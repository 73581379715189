import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IFreshCatNames {
  active: string[];
  cancelled: string[];
}

interface IUserDetails {
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  legacyId?: string;
  catNames?: IFreshCatNames;
}

interface IUserDetailsStore extends IUserDetails {
  setEmail(newEmail: string | undefined): void;
  setFirstName(updatedFirstName: string | undefined): void;
  setLastName(updatedLastName: string | undefined): void;
  setPhoneNumber(updatedPhoneNumber: string | undefined): void;
  setId(updatedId: string | undefined): void;
  setLegacyId(updatedLegacyId: string | undefined): void;
  setUserDetails(updatedUserDetails: IUserDetails | undefined): void;
  unsetUserDetails(): void;
  setCatNames(updatedCatNames: IFreshCatNames): void;
}

export const useUserStore = create<IUserDetailsStore>()(persist((set) => ({
  setEmail: (newEmail: string) => set((state) => ({
    ...state,
    email: newEmail }
  )),
  setPhoneNumber: (updatedPhoneNumber: string) => set((state) => ({
    ...state,
    phoneNumber: updatedPhoneNumber }
  )),
  setFirstName: (updatedFirstName: string) => set((state) => ({
    ...state,
    firstName: updatedFirstName }
  )),
  setLastName: (updatedLastName: string) => set((state) => ({
    ...state,
    lastName: updatedLastName }
  )),
  setId: (updatedId: string) => set((state) => ({
    ...state,
    id: updatedId }
  )),
  setLegacyId: (updatedLegacyId: string) => set((state) => ({
    ...state,
    legacyId: updatedLegacyId }
  )),
  setCatNames: (updatedCatNames: IFreshCatNames) => set((state) => ({
    ...state,
    cats: updatedCatNames,
  }
  )),
  setUserDetails: (updatedUserDetails: IUserDetails) => set((state) => ({
    ...state,
    ...updatedUserDetails,
  })),
  unsetUserDetails: () => set(() => ({})),
}), { name: "user-store" }));
