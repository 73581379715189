"use client";

import { FC, useEffect, useMemo } from "react";
import { usePathname, useSearchParams } from "next/navigation";

interface IDatadogPathChangeProps {
  onLogRouteChange(): void;
  onStartSessionReplayRecording(): void;
  onStopSessionReplayRecording(): void;
}

const DatadogPathChange: FC<IDatadogPathChangeProps> = ({
  onLogRouteChange, onStartSessionReplayRecording, onStopSessionReplayRecording,
}) => {

  const pathName = usePathname();
  const searchParams = useSearchParams();
  const searchParamsString = useMemo(() => searchParams.toString(), [ searchParams ]);

  useEffect(() => {
    function logRouteChange() {
      onLogRouteChange();
    }
    logRouteChange();
    onStartSessionReplayRecording();

    return () => {
      onStopSessionReplayRecording();
    };
  }, [ pathName, searchParamsString ]);

  return <></>;
};

export default DatadogPathChange;
