import type { LogLevel } from "@nestjs/common";

export type LogLevelWithVerbose = LogLevel | "info" | "trace";

/** A map from appName to context to a list of logLevels that should not be logged.
 * The special value "ALL" that matches everything can be used for appName and context.
 * Any logLevel that isn't a {@link LogLevelWithVerbose} will be silently ignored. */
export type PerServiceDisabledLogLevels = ReadonlyMap<string, ReadonlyMap<string, ReadonlySet<string>>>;

export function parseLogLevels(logLevels: unknown): ReadonlySet<string> | undefined {
  if (!logLevels) return undefined;
  const logLevelString = `${logLevels}`.trim();
  if (!logLevelString) {
    // eslint-disable-next-line no-console
    console.error("bad log levels", logLevels);
    return undefined;
  }
  if (logLevelString.toUpperCase() === "ALL") return new Set("all");
  if (logLevelString.toUpperCase() === "NONE") return new Set();
  return new Set(logLevelString.split(",").map((it) => it.trim().toLowerCase()));
}

/** @param services a string in the following format app:context:logLevel,logLevel | context:logLevel
 * @return a mapping from an app, to a context to a set of log levels.
 * The app ALL and context ALL are special in that they apply to all cases without a more specific config.
 * The logLevel ALL applies all log levels to that context.
 * The logLevel NONE applies no log levels to that context.
 * @see {@link logLevelDisabled} for more details */
export function parseServices(services: unknown): PerServiceDisabledLogLevels {
  if (!services) return new Map();
  const servicesString = `${services}`.trim();
  if (!servicesString) {
    // eslint-disable-next-line no-console
    console.error("bad services", services);
    return new Map();
  }
  const splitServices = servicesString.split("|").map((it) => it.trim());
  const retMap: Map<string, Map<string, ReadonlySet<string>>> = new Map();
  for (const splitService of splitServices) {
    const splitDefinition = splitService.split(":").map((it) => it.trim());
    if (splitDefinition.length < 2 || splitDefinition.length > 3) {
      // eslint-disable-next-line no-console
      console.error("bad service definition", splitService);
      continue;
    }
    const app = splitDefinition.length === 3 ? splitDefinition[0] : "ALL";
    const context = splitDefinition.length === 3 ? splitDefinition[1] : splitDefinition[0];
    const logLevels = parseLogLevels(splitDefinition.length === 3 ? splitDefinition[2] : splitDefinition[1]);
    if (!app || !context || !logLevels) {
      // eslint-disable-next-line no-console
      console.error("bad service definition, failed to parse", splitService);
      continue;
    }
    if (!retMap.has(app)) {
      retMap.set(app, new Map());
    }
    const appContextMap = retMap.get(app)!;
    if (!appContextMap.has(context)) {
      appContextMap.set(context, logLevels);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Conflicting definition for ${app}:${context} -> `, appContextMap.get(context), " X ", logLevels);
    }
  }
  return retMap;
}

export function undefinedIfEmptySelfIfSingle<T>(array: T[]): T[] | T | undefined {
  return array.length === 0 ? undefined : array.length === 1 ? array[0] : array;
}

export function parseDDTagsFromEnv(): NodeJS.Dict<string> | undefined {
  const ddTagsEnv = process.env.DD_TAGS;
  if (!ddTagsEnv) return undefined;
  const splitTags = ddTagsEnv.split(",");
  const transformedTags = splitTags.map((tag, index) => {
    const splitTag = tag.split(":");
    const key = splitTag[0].replace(/\./g, "__");
    const value = splitTag.slice(1).join(":");
    return [
      key || `noKey${index}`,
      value || `!!empty${index}!!`,
    ] as const;
  }).filter((it): it is [string, string] => !!it);
  if (!transformedTags.length) return undefined;
  return Object.fromEntries(transformedTags);
}
