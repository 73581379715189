import { MeowConfig } from "@meow/core";

import { parseLogLevels, parseServices } from "./utils";

/** Retrieves the default Meow logger configuration.
 * Overrides can be provided via the environment.
 * @param config if provided, used to override the configuration retrieved
 * from env-vars and/or the default configuration. */
export function getDefaultMeowArgs(config?: Partial<MeowConfig>): MeowConfig {

  const devStyleLogs = config?.devStyleLogs
    ?? ((process.env.ANIKIN_ENVIRONMENT === "dev" && process.env.DEV_LOGS !== "false") || process.env.DEV_LOGS === "true");
  const perServiceDisabledLogLevels = config?.perServiceDisabledLogLevels
    ?? parseServices(process.env.DISABLED_LOG_LEVELS_PER_SERVICE);
  const disabledLogLevels = config?.disabledLogLevels
    ?? (parseLogLevels(process.env.DISABLED_LOG_LEVELS)
      || (devStyleLogs ? new Set() : new Set([ "verbose" ])));

  return {
    devStyleLogs,
    disabledLogLevels,
    perServiceDisabledLogLevels,
    addTraces: config?.addTraces,
  };
}
