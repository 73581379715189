"use client";

import { useEffect } from "react";

import { useAuth } from "./useAuth";

export function useRedirectIfLoggedIn(redirectTo = "/") {
  const auth = useAuth();

  useEffect(() => {
    if (auth.loggedIn) {
      // Note: we use window href instead of Next router because
      // of cross-application transitions
      // TODO sanitize URL to prevent redirect attacks
      //   see https://cheatsheetseries.owasp.org/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet.html
      window.location.href = redirectTo;
    }
  }, [ auth.loggedIn, redirectTo ]);
}
