import Axios, { AxiosInstance } from "axios";

import type {
  AccessToken,
  CreateMagicLinkRequest,
  CreateUserRequest,
  CreateUserResponse,
  ILoginSuccessResponse,
  ITokenSuccessResponse,
  JsonWebTokenPayload,
  LoginRequest,
  LoginSuccessResponse,
  MagicLinkInvalidResponse,
  UseMagicLinkRequest,
} from "@auth/types";
import { TokenRequest } from "@auth/types";

import { MagicLinkCreationResult } from "./types";

export class AuthSDK {

  private readonly axios: AxiosInstance;

  constructor(
    baseURLAuth: string,
  ) {
    this.axios = Axios.create({ baseURL: baseURLAuth });
  }

  public async loginWithEmailPassword(
    email: string,
    password: string,
  ): Promise<ILoginSuccessResponse> {

    const data: LoginRequest = { grant_type: "password", username: email, password };
    const response = await this.axios.post<ILoginSuccessResponse>(
      "login",
      data,
    );

    return response.data;
  }

  public async sendMagicLink(email: string, redirectTo?: string): Promise<MagicLinkCreationResult> {

    const data: CreateMagicLinkRequest = { email, redirectTo };

    try {
      await this.axios.put(
        "magic-link",
        data,
      );

      return MagicLinkCreationResult.SUCCESS;
    } catch (err) {
      if (Axios.isAxiosError(err)) {
        if (err.response?.status === 404) return MagicLinkCreationResult.NOT_FOUND;
        return MagicLinkCreationResult.OTHER_ERROR;
      }

      return MagicLinkCreationResult.OTHER_ERROR;
    }
  }

  public async useMagicLink(code: string): Promise<LoginSuccessResponse | MagicLinkInvalidResponse> {
    const data: UseMagicLinkRequest = { code };

    try {
      const response = await this.axios.post<LoginSuccessResponse>("magic-link", data);
      return response.data;
    } catch (err) {
      if (Axios.isAxiosError(err)) {
        const errorData = err.response?.data as MagicLinkInvalidResponse;
        return errorData;
      }
      throw err;
    }
  }

  public async getAccessTokenFromRefreshToken(
    refreshToken: string,
  ): Promise<ITokenSuccessResponse> {

    const data: TokenRequest = { grant_type: "refresh_token", refresh_token: refreshToken };
    const response = await this.axios.post<ITokenSuccessResponse>(
      "token",
      data,
    );

    return response.data;
  }

  public async createUser(
    userRequest: CreateUserRequest,
  ): Promise<CreateUserResponse> {
    const response = await this.axios.post<CreateUserResponse>(
      "create-user",
      userRequest,
    );

    return response.data;
  }

  public static decode(jwt: AccessToken): JsonWebTokenPayload {
    const [ _, payloadRaw ] = jwt.split(".");

    const payloadString = Buffer.from(payloadRaw, "base64").toString();
    const parsed = JSON.parse(payloadString);
    return parsed;
  }

}
