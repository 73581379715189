/** All of these path suffixes will be scrubbed unconditionally
 * If you define a path like this { pathToScrub: [ "A", "B" ] },
 * then B will be scrubbed in this case { "C": { "A": { "B": "scrubbed" } } }
 * but not in this case { "A": { "C": { "B": "not-scrubbed" } } }
 * nor this case { "B": "not-scrubbed" }
 */
const PATHS_TO_SCRUB: ReadonlyArray<{ pathToScrub: readonly [string, ...string[]] }> = [
  { pathToScrub: [ "setupIntentClientSecret" ] },
];
// If a string matches this regex, it will be anonymised through the given function
// If multiple regexes match, then the first one will be used
const REGEX_TO_CHECK: ReadonlyArray<{ regex: RegExp, replacement: (toReplace: string) => string }> = [
  {
    regex: /seti_[^_]+_secret_/,
    replacement: (toReplace: string) => toReplace.replace(/(seti_[^_]+_secret_)[a-zA-Z0-9]+/, "$1SCRUBBED"),
  },
];

interface SeenItem<T> {
  item: T;
  replacement: T;
}

function copyObject<T extends object>(returnCopy: boolean, objectToScrub: T): T {
  if (!returnCopy) return objectToScrub;
  if (Array.isArray(objectToScrub)) return [ ...objectToScrub ] as T;
  return { ...objectToScrub };
}

function getObjectToScrub<T extends object>(
  returnCopy: boolean,
  convertToJsonBeforeScrubbing: boolean,
  inObjectToScrub: T,
  path: (string | number)[],
): T | string | number | bigint | undefined | null {
  if (returnCopy
    && convertToJsonBeforeScrubbing
    && "toJSON" in inObjectToScrub
    && typeof inObjectToScrub.toJSON === "function") {
    try {
      const transformedToJSON = JSON.stringify(inObjectToScrub.toJSON(path.at(-1) ?? ""));
      const toParse = `{"data":${transformedToJSON}}`;
      try {
        const parsed = JSON.parse(toParse).data;
        if (
          typeof parsed === "string" ||
          typeof parsed === "number" ||
          typeof parsed === "bigint" ||
          typeof parsed === "undefined" ||
          typeof parsed === "object"
        ) {
          return parsed;
        }
        console.warn(`Unexpected type ${typeof parsed} for re-parsed ${toParse} in ${toJSONPath(path)}`);
        return inObjectToScrub;
      } catch (e) {
        console.warn(`could not parse JSON ${toParse} in ${toJSONPath(path)}`, e);
        return inObjectToScrub;
      }
    } catch (e) {
      console.warn(`could not convert toJSON ${inObjectToScrub} of type ${typeof inObjectToScrub} in ${toJSONPath(path)}`, e);
      return inObjectToScrub;
    }
  } else {
    return inObjectToScrub;
  }
}

/** Will replace sensitive data in the object.
 * If scrubbing fails, modification will stop and the original object will be returned.
 * If returnCopy is false, then the original object may have been partially modified.
 * @param inObjectToScrub the object to check
 * @param removeDuplicates if set to true,
 * then duplicate objects encountered will be replaced with "[Duplicate PATH]" where PATH is the
 * JSONPath expression describing where the item was last seen.
 * This will prevent issues with serialising transient dependencies.
 * @param returnCopy if set to true, scrubbing will be made on a copy of the given object.
 * This will be set to true on the first level of recursion,
 * to ensure we don't end up modifying objects that are currently in use by others.
 * This should usually be false on the initial call,
 * since we usually either want to modify the top-level object in place
 * or don't care if it's modified because it's a temp object.
 * @param convertToJsonBeforeScrubbing if set to true,
 * then objects that have a toJSON method will be transformed to JSON and back before scrubbing.
 * If the result of parsing the
 * @param seen all objects seen so far, to prevent loops
 * @param path path followed so far */
export function scrubSensitiveData<T extends object>(
  inObjectToScrub: T,
  {
    removeDuplicates = false,
    returnCopy = false,
    convertToJsonBeforeScrubbing = true,
    seen = [],
    path = [],
  }: {
    removeDuplicates?: boolean,
    returnCopy?: boolean,
    convertToJsonBeforeScrubbing?: boolean,
    seen?: SeenItem<unknown>[],
    path?: (string | number)[]
  } = {},
): {
    replacement: T | string | number | bigint | null | undefined,
    scrubbed: boolean
  } {
  try {
    // First, we check to see if we've already encountered this object
    const replacementAlreadyExists = seen.find(({ item }) => item === inObjectToScrub) as SeenItem<T> | undefined;

    // If we have, we return the already calculated object (handles self-reference/infinite-recursion)
    if (replacementAlreadyExists?.item === inObjectToScrub) {
      if (removeDuplicates) {
        // console.log("removed duplicate", toJSONPath(path), removeDuplicates);
        return {
          replacement: `[DUPLICATE ${toJSONPath(path)}]`,
          scrubbed: true,
        };
      }
      // console.log("returned duplicate", toJSONPath(path), removeDuplicates);
      return {
        replacement: replacementAlreadyExists.replacement,
        scrubbed: false,
      };
    }

    // If the object is going to change shape through a toJSON function,
    // then scrub the result of the transformation rather than the source.
    const objectToScrubTransformed = getObjectToScrub(
      returnCopy,
      convertToJsonBeforeScrubbing,
      inObjectToScrub,
      path,
    );
    // If the object ended up becoming something other than an object, then return that thing
    if (typeof objectToScrubTransformed !== "object" || objectToScrubTransformed === null) {
      // If the thing it turned into is a string, try to scrub the string through REGEX
      if (typeof objectToScrubTransformed === "string") {
        const replacementRegex = REGEX_TO_CHECK.find(({ regex }) => objectToScrubTransformed.match(regex));
        if (replacementRegex) {
          return {
            replacement: replacementRegex.replacement(objectToScrubTransformed),
            scrubbed: true,
          };
        }
      }
      return {
        replacement: objectToScrubTransformed,
        scrubbed: false,
      };
    }
    const objectToScrub: T = objectToScrubTransformed;

    // This will change from undefined when we create a copy of the object
    let scrubData: { copy: T, newSeen: SeenItem<unknown>[] } | undefined;

    function getScrubData(): { copy: T, newSeen: SeenItem<unknown>[] } {
      if (scrubData === undefined) {
        const copy = copyObject(returnCopy, objectToScrub);
        const newSeen = [ ...seen, { item: inObjectToScrub, replacement: copy } ];
        scrubData = { copy, newSeen };
      }
      return scrubData;
    }

    // This will change to true if we scrub anything in this object or its parent objects
    let scrubbed = false;

    // For every item in the object
    (Array.isArray(objectToScrub)
      ? objectToScrub.map((value, index) => [ index, value ])
      : Object.entries(objectToScrub)).forEach(([ key, itemToCheck ]) => {
      const type = typeof itemToCheck;
      switch (type) {
        case "object": {
          const toCheck: NonNullable<unknown> | null = itemToCheck as object;
          // No need to scrub null objects
          if (toCheck === null) {
            break;
          }

          // If we need to scrub the entire object, then do that
          if (pathMatches([ ...path, key ])) {
            // @ts-ignore
            getScrubData().copy[key] = scrub(itemToCheck);
            // console.log("scrubbed string path", toJSONPath([ ...path, key ]));
            scrubbed = true;
            break;
          }

          // Else, we'll need to check the object recursively
          const iScrubData = getScrubData();
          const replacement = scrubSensitiveData(
            toCheck,
            {
              // If the object defines its own toJSON function, assume it knows how to remove duplicates
              removeDuplicates: removeDuplicates
                && "toJSON" in toCheck
                && typeof toCheck.toJSON === "function",
              returnCopy: true,
              seen: iScrubData.newSeen,
              path: [ ...path, key ],
            },
          );
          // If we ended up scrubbing recursively, replace the object with the object copy
          if (replacement.scrubbed) {
            // @ts-ignore
            iScrubData.copy[key] = replacement.replacement;
            // console.log("scrubbed object", toJSONPath([ ...path, key ]));
            scrubbed = true;
          }
          break;
        }
        case "undefined":
        case "boolean":
        case "symbol":
        case "function":
          // I assume we'll never want to scrub one of these
          break;
        case "string": {
          const stringToCheck: string = itemToCheck;
          const replacementRegex = REGEX_TO_CHECK.find(({ regex }) => stringToCheck.match(regex));
          if (replacementRegex) {
            // @ts-ignore
            getScrubData().copy[key] = replacementRegex.replacement(itemToCheck);
            // console.log("scrubbed string regex", toJSONPath([ ...path, key ]));
            scrubbed = true;
            break;
          }
          if (pathMatches([ ...path, key ])) {
            // @ts-ignore
            getScrubData().copy[key] = scrub(itemToCheck);
            // console.log("scrubbed string path", toJSONPath([ ...path, key ]));
            scrubbed = true;
          }
          break;
        }
        case "number":
        case "bigint":
          if (pathMatches([ ...path, key ])) {
            // @ts-ignore
            getScrubData().copy[key] = scrub(itemToCheck);
            // console.log("scrubbed number", toJSONPath([ ...path, key ]));
            scrubbed = true;
          }
          break;
        default: {
          // If we don't know the type, do nothing
          const never: never = type;
          // eslint-disable-next-line no-console
          console.warn(`Scrubber encountered unknown type ${never}`);
          break;
        }
      }
    });
    if (scrubbed) {
      // console.log("scrubbed", toJSONPath(path), removeDuplicates);
      return {
        replacement: getScrubData().copy,
        scrubbed: true,
      };
    }
    // If we didn't change anything, there's no point in returning the copy
    return {
      replacement: inObjectToScrub,
      scrubbed: false,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Failed to scrub ${toJSONPath(path)}`, e);
    return {
      replacement: inObjectToScrub,
      scrubbed: false,
    };
  }
}

/** @return true if the current path suffix matches one of the paths to scrub */
function pathMatches(currPath: string[]): boolean {
  return PATHS_TO_SCRUB.some(({ pathToScrub }) => pathToScrub.length <= currPath.length
    && pathToScrub.every((pathSegment, index) => pathSegment === currPath[
      currPath.length - pathToScrub.length + index
    ]));
}

/** @return The string SCRUBBED#L where L is the length of the scrubbed value when transformed into a string. */
function scrub(toScrub: unknown): string {
  return `SCRUBBED#${`${toScrub}`.length}`;
}

/** @return The given path as a JSONPath, assuming the root of the path is an object */
function toJSONPath(path: (string | number)[]): string {
  return `$${path.map((it) => (typeof it === "number" ? `[${it}]` : `.${it}`)).join("")}`;
}
